import { createSlice } from '@reduxjs/toolkit';

const generalSlice = createSlice({
  name: 'general',
  initialState: {
    device: null,
    salePageURL: null,
  },
  reducers: {
    refreshDevice(state, action) {
      state.device = action.payload;
    },
    refreshSalePageURL(state, action) {
      state.salePageURL = action.payload;
    },
  },
});

export const { refreshDevice, refreshSalePageURL } = generalSlice.actions;

export default generalSlice.reducer;
