import PropTypes from 'prop-types';
import { useState } from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import Nav from 'react-bootstrap/Nav';
import LibNavbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import { config } from '_config';
import { theme } from '_constants/theme';
import { ROUTES } from '_constants/routes';

import Button from '_components/atomic/Button';

const StyledContainer = styled.div`
  .navbar {
    .navbar-brand {
      padding-top: ${theme.spaces.smallest}px;
      padding-bottom: ${theme.spaces.smallX}px;
    }

    .navbar-toggler {
      border: none;
      padding: unset;

      &:focus {
        outline: unset;
        box-shadow: none;
      }
    }

    .navbar-collapse {
      .navbar-nav {
        .nav-link {
          padding-left: ${theme.spaces.regular}px;
          padding-right: ${theme.spaces.regular}px;
          font-family: 'Inter';
          font-size: ${theme.spaces.regular}px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: -0.03em;
          text-align: right;
          color: ${theme.colors.gray.secondary};

          &:hover {
            text-decoration: underline;
          }
        }

        .nav-item {
          .dropdown-menu {
            border: 1px solid #f2f4f7;

            .dropdown-item {
              padding-top: ${theme.spaces.smallX}px;
              padding-bottom: ${theme.spaces.smallX}px;
              font-family: 'Inter';
              font-size: ${theme.spaces.regular}px;
              font-weight: 600;
              line-height: 24px;
              letter-spacing: -0.03em;
              text-align: left;
              color: ${theme.colors.gray.secondary};

              :hover {
                background: #f9f5ff;
              }
            }
          }
        }
      }

      button {
        margin-left: ${theme.spaces.small}px;
      }
    }
  }

  @media ${`(min-width: ${theme.displaySizes.tablet})`} {
    .navbar {
      .navbar-nav {
        .nav-item {
          &.dropdown:hover {
            .dropdown-menu {
              display: block;
              margin-top: 0;
            }
          }
        }
      }
    }
  }

  @media ${theme.devices.tablet} {
    .navbar {
      .navbar-collapse {
        .navbar-nav {
          padding-top: ${theme.spaces.regular}px;
          padding-bottom: ${theme.spaces.regular}px;

          .nav-item {
            .dropdown-toggle {
              display: inline-flex;
              flex-direction: row-reverse;
              align-items: center;
              width: 100%;

              &:after {
                margin-right: 0.255em;
              }
            }
          }
        }

        button {
          justify-content: center;
          width: 100%;
          margin-top: ${theme.spaces.regular}px;
          margin-left: 0;
        }
      }
    }
  }
`;

const StyledImageContainer = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const NavDropdownItem = ({ title, path }) => {
  return (
    <Link href={path} legacyBehavior passHref>
      <NavDropdown.Item>{title}</NavDropdown.Item>
    </Link>
  );
};

const NavItem = ({ title, path, subitems }) => {
  if (subitems) {
    return (
      <NavDropdown
        title={title}
        id={`nav-dropdown-${subitems[0].path}`}
        renderMenuOnMount={true}
      >
        {subitems.map((item) => (
          <NavDropdownItem key={item.path} {...item} />
        ))}
      </NavDropdown>
    );
  }

  return (
    <Link href={path} legacyBehavior passHref>
      <Nav.Link>{title}</Nav.Link>
    </Link>
  );
};

const Navbar = ({ items }) => {
  const [open, setOpen] = useState(false);

  return (
    <StyledContainer>
      <LibNavbar collapseOnSelect expand="md" onToggle={setOpen}>
        <LibNavbar.Brand>
          <Link href={ROUTES.home}>
            <StyledImageContainer>
              <picture>
                <source
                  srcSet={`${config.basePath}/images/logo.svg`}
                  type="image/svg+xml"
                />
                <img src={`${config.basePath}/images/logo.svg`} alt="" />
              </picture>
            </StyledImageContainer>
          </Link>
        </LibNavbar.Brand>
        <LibNavbar.Toggle aria-controls="main-navbar-nav">
          {!open && (
            <StyledImageContainer>
              <picture>
                <source
                  srcSet={`${config.basePath}/images/menu-burger.svg`}
                  type="image/svg+xml"
                />
                <img src={`${config.basePath}/images/menu-burger.svg`} alt="" />
              </picture>
            </StyledImageContainer>
          )}
          {open && (
            <StyledImageContainer>
              <picture>
                <source
                  srcSet={`${config.basePath}/images/menu-close.svg`}
                  type="image/svg+xml"
                />
                <img src={`${config.basePath}/images/menu-close.svg`} alt="" />
              </picture>
            </StyledImageContainer>
          )}
        </LibNavbar.Toggle>
        <LibNavbar.Collapse id="main-navbar-nav">
          <Nav className="me-auto">
            {items.map((item) => (
              <NavItem key={item.path} {...item} />
            ))}
          </Nav>
          <Link href={ROUTES.optInGeneral}>
            <Button desktop secondary rounded>
              Log In
            </Button>
          </Link>
          <Link href={ROUTES.contactUs}>
            <Button rounded>Talk to an Expert</Button>
          </Link>
          <Link href={ROUTES.optInGeneral}>
            <Button mobile secondary rounded>
              Log In
            </Button>
          </Link>
        </LibNavbar.Collapse>
      </LibNavbar>
    </StyledContainer>
  );
};

Navbar.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.node,
      path: PropTypes.string,
      subitems: PropTypes.shape({
        title: PropTypes.node,
        path: PropTypes.string,
      }),
    })
  ),
};

export default Navbar;
