export const createQueryString = (params = {}) => {
  const urlObjectInstance = new URLSearchParams(params);
  const queryString = urlObjectInstance.toString();
  return queryString.length ? `?${queryString}` : '';
};

export const getSalePageURL = () => {
  let salePage = window.location.href;
  let salePageURLArr = salePage.split('/');
  return (
    salePageURLArr[0] +
    '//' +
    salePageURLArr[2] +
    process.env.NEXT_PUBLIC_BASE_PATH
  ).replace(/\/+$/, '');
};
