import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { DefaultSeo } from 'next-seo';
import GTM from 'react-gtm-module';

import 'styles/index.css';

import { config } from '_config';
import { theme } from '_constants/theme';
import { wrapper } from '_redux/store';

import MainLayout from '_components/composed/layouts/MainLayout';

function App({ Component, ...restProps }) {
  const { store, props } = wrapper.useWrappedStore(restProps);

  useEffect(() => {
    import('bootstrap/dist/js/bootstrap');
    // GTM.initialize({ gtmId: config.gtmId });
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <MainLayout>
          <DefaultSeo titleTemplate="%s | Monexa" defaultTitle="Monexa" />
          <Component {...props.pageProps} />
        </MainLayout>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
