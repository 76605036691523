import styled, { css } from 'styled-components';
import Link from 'next/link';

import { config } from '_config';
import { SECTION_ID } from '_constants/ui';
import { ROUTES } from '_constants/routes';
import { theme } from '_constants/theme';

const StyledOuterContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: ${theme.colors.white};
`;

const StyledInnerContainer = styled.div`
  max-width: ${theme.spaces.large30X * 10}px;
  width: 100%;

  padding-top: ${theme.spaces.large}px;
  padding-bottom: ${theme.spaces.large}px;
  padding-left: ${theme.spaces.large28X - 1}px;
  padding-right: ${theme.spaces.large28X - 1}px;

  @media ${theme.devices.tablet} {
    padding-left: ${theme.spaces.regular}px;
    padding-right: ${theme.spaces.regular}px;
  }
`;

const StyledImageContainer = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const StyledSpace = styled.span`
  display: inline-block;
  width: 16px;
`;

const StyledText = styled.span`
  display: inline-block;
  font-family: 'Inter';
  font-size: ${theme.spaces.regular}px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: ${theme.colors.gray.secondary};

  ${({ link }) =>
    link &&
    css`
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    `}
`;

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  /* width: 100%; */

  @media ${theme.devices.tablet} {
    flex-direction: column;

    ${({ reverse }) =>
      reverse &&
      css`
        flex-direction: column-reverse;
      `}
  }
`;

const StyledCol = styled.div`
  padding-top: ${theme.spaces.regular}px;
  padding-bottom: ${theme.spaces.regular}px;

  ${({ right }) =>
    right &&
    css`
      display: flex;
      justify-content: flex-end;
    `}

  @media ${theme.devices.tablet} {
    display: flex;
    justify-content: center;
  }
`;

const Footer = () => {
  return (
    <StyledOuterContainer>
      <StyledInnerContainer>
        <StyledRow>
          <StyledCol>
            <Link href={ROUTES.home}>
              <StyledImageContainer>
                <picture>
                  <source
                    srcSet={`${config.basePath}/images/logo.svg`}
                    type="image/svg+xml"
                  />
                  <img src={`${config.basePath}/images/logo.svg`} alt="" />
                </picture>
              </StyledImageContainer>
            </Link>
          </StyledCol>
        </StyledRow>
        <StyledRow>
          <StyledCol>
            <StyledText>Your trusted accounting partner</StyledText>
          </StyledCol>
          <StyledCol right>
            <Link href={`${ROUTES.home}?sectionId=${SECTION_ID.about}`}>
              <StyledText link>Monexa</StyledText>
            </Link>
            <StyledSpace />
            <Link href={`${ROUTES.home}?sectionId=${SECTION_ID.solutions}`}>
              <StyledText link>Solutions</StyledText>
            </Link>
            <StyledSpace />
            <Link href={`${ROUTES.home}?sectionId=${SECTION_ID.clients}`}>
              <StyledText link>Clients</StyledText>
            </Link>
            <StyledSpace />
            <Link href={`${ROUTES.home}?sectionId=${SECTION_ID.faq}`}>
              <StyledText link>FAQ</StyledText>
            </Link>
            <StyledSpace />
            <Link href={`${ROUTES.home}?sectionId=${SECTION_ID.contacts}`}>
              <StyledText link>Contacts</StyledText>
            </Link>
          </StyledCol>
        </StyledRow>
        <StyledRow reverse>
          <StyledCol>
            <StyledText>©{new Date().getFullYear()}&nbsp;</StyledText>
            <Link href={ROUTES.home}>
              <StyledText link>monexa.io</StyledText>
            </Link>
            <StyledText>.&nbsp;All rights reserved.</StyledText>
          </StyledCol>
          <StyledCol right>
            <Link href={ROUTES.privacy}>
              <StyledText link>Privacy</StyledText>
            </Link>
            <StyledSpace />
            <Link href={ROUTES.terms}>
              <StyledText link>Terms of Service</StyledText>
            </Link>
          </StyledCol>
        </StyledRow>
      </StyledInnerContainer>
    </StyledOuterContainer>
  );
};

export default Footer;
