import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';

import { getDeviceType } from '_helpers/device';
import { getSalePageURL } from '_helpers/urls';

import { refreshDevice, refreshSalePageURL } from '_redux/general/slice';
import { createClick } from '_redux/location/slice';
import { selectClickData } from '_redux/location/selector';

import Header from '_components/composed/layouts/Header';
import Footer from '_components/composed/layouts/Footer';

const StyledContainer = styled.div`
  overflow: hidden;
`;

const MainLayout = ({ children }) => {
  const headerRef = useRef(null);
  const headerSpaceElemRef = useRef(null);
  const router = useRouter();
  const dispatch = useDispatch();
  const clickData = useSelector(selectClickData);

  const handleResize = () => {
    if (!isMobile && headerSpaceElemRef.current && headerRef.current) {
      headerSpaceElemRef.current.style.marginTop =
        headerRef.current.getBoundingClientRect().height + 'px';
    }
  };

  useEffect(() => {
    if (router.isReady && !clickData) {
      dispatch(createClick());
    }
  }, [dispatch, router, clickData]);

  useEffect(() => {
    dispatch(refreshDevice(getDeviceType()));
    dispatch(refreshSalePageURL(getSalePageURL()));
  }, [dispatch]);

  useEffect(() => {
    const handleRouteChange = (url) => {
      dispatch(createClick());
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [dispatch, router]);

  useEffect(() => {
    if (headerSpaceElemRef.current && headerRef.current) {
      headerSpaceElemRef.current.style.marginTop =
        headerRef.current.getBoundingClientRect().height + 'px';
    }

    window.addEventListener('resize', handleResize, false);

    return () => {
      window.removeEventListener('resize', handleResize, false);
    };
  }, []);

  return (
    <StyledContainer>
      <Header containerRef={headerRef} />
      <div id="headerSpaceElem" ref={headerSpaceElemRef}></div>
      {children}
      <Footer />
    </StyledContainer>
  );
};

export default MainLayout;
