import { PAGE_TYPE } from '_constants/location';
import { STORAGE_KEYS } from '_constants/storage';
import { ROUTES } from '_constants/routes';
import { storeLocalValue, restoreLocalValue } from '_helpers/storage';

export const createClickBody = (location, device) => {
  const { gclid, source, keyword, adAccount } = location.query || {};

  if (gclid) {
    storeLocalValue(
      STORAGE_KEYS.gclid,
      gclid.match(/[a-zA-Z0-9 -_&.\\/]+/gi).join('')
    );
  }

  if (source) {
    storeLocalValue(
      STORAGE_KEYS.source,
      source.match(/[a-zA-Z0-9 -_&.\\/]+/gi).join('')
    );
  }

  if (keyword) {
    storeLocalValue(
      STORAGE_KEYS.keyword,
      keyword.match(/[a-zA-Z0-9 -_&.\\/]+/gi).join('')
    );
  }

  if (adAccount) {
    storeLocalValue(
      STORAGE_KEYS.adAccount,
      adAccount.match(/[a-zA-Z0-9 -_&.\\/]+/gi).join('')
    );
  }

  const click = {
    device,
    pageType: PAGE_TYPE.presellPage,
    requestUri: window.location.href,
  };

  if (adAccount || keyword || source || gclid) {
    storeLocalValue(STORAGE_KEYS.requestUri, window.location.href);
  }

  if (
    restoreLocalValue(STORAGE_KEYS.requestUri)?.includes(window.location.href)
  ) {
    click.requestUri = restoreLocalValue(STORAGE_KEYS.requestUri);
  }

  if (location.pathname.includes(ROUTES.optInGeneral)) {
    click.pageType = PAGE_TYPE.leadPage;
  }

  if (location.pathname.includes(ROUTES.optInBookkeeping)) {
    click.pageType = PAGE_TYPE.leadPage;
  }

  if (location.pathname.includes(ROUTES.optInTaxPreparation)) {
    click.pageType = PAGE_TYPE.leadPage;
  }

  if (location.pathname.includes(ROUTES.payment)) {
    click.pageType = PAGE_TYPE.checkoutPage;
  }

  if (location.pathname.includes(ROUTES.upsell)) {
    click.pageType = PAGE_TYPE.upsellPage;
  }

  if (location.pathname.includes(ROUTES.thankyou)) {
    click.pageType = PAGE_TYPE.thankyouPage;
  }

  if (
    restoreLocalValue(STORAGE_KEYS.crmSessionId) &&
    click.pageType !== PAGE_TYPE.presellPage
  ) {
    click.crmSessionId = restoreLocalValue(STORAGE_KEYS.crmSessionId);
  }

  return click;
};
