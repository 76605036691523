import { config } from '_config';
import { AxiosClient } from '_api/AxiosClient';

const { apiUrl } = config;

class xVerifyApi extends AxiosClient {
  constructor(url) {
    super(url);
    this.verifyEmail = this.verifyEmail.bind(this);
  }

  verifyEmail(email) {
    return new Promise((resolve) => {
      this.client
        .post(`/customers/email/verify?email=${email}`)
        .then((response) => {
          const data = response?.data ? { ...response?.data } : {};

          if (
            data?.reason === this.EMAIL_REASON.complainer ||
            data?.reason === this.EMAIL_REASON.unverifiable
          ) {
            data.status = this.EMAIL_STATUS.valid;
          }

          resolve({
            ...data,
            status:
              data.status === '' ? this.EMAIL_STATUS.badRequest : data.status,
          });
        })
        .catch((e) => {
          resolve({ status: this.EMAIL_STATUS.valid });
        });
    });
  }

  EMAIL_STATUS = {
    valid: 'valid',
    invalid: 'invalid',
    acceptAll: 'accept_all',
    risky: 'risky',
    unknown: 'unknown',
    badRequest: 'bad_request',
  };

  EMAIL_REASON = {
    verified: 'verified',
    invalidSyntax: 'invalid_syntax',
    invalidDomain: 'invalid_domain',
    mailboxFull: 'mailbox_full',
    invalidMailbox: 'invalid_mailbox',
    unverifiable: 'unverifiable',
    highRisk: 'high_risk',
    complainer: 'complainer',
    fraud: 'fraud',
    blockAffiliate: 'block_affiliate',
    blockByUser: 'block_by_user',
    blockCountry: 'block_country',
    noResult: 'no_result',
  };

  VALIDATION_MESSAGE = {
    [this.EMAIL_REASON.verified]: 'Verified!',
    [this.EMAIL_REASON.invalidSyntax]: 'Syntax or spelling error',
    [this.EMAIL_REASON.invalidDomain]:
      'Domain does not exist or does not accept email',
    [this.EMAIL_REASON.mailboxFull]:
      'Mailbox is over quota and is currently rejecting email',
    [this.EMAIL_REASON.invalidMailbox]: "Mailbox doesn't exist",
    [this.EMAIL_REASON.unverifiable]:
      'Domain is a catch all and does not support validation',
    [this.EMAIL_REASON.highRisk]:
      'Address is a spamtrap, a known complainer or is suppressed',
    [this.EMAIL_REASON.complainer]:
      'Address has complained about receiving commercial email',
    [this.EMAIL_REASON.fraud]:
      'Address was used in a fraudulent transaction, e.g. credit card fraud',
    [this.EMAIL_REASON.blockAffiliate]:
      'The affiliate submitting data is blocked in your user settings',
    [this.EMAIL_REASON.blockByUser]:
      'This address is blocked in your user settings',
    [this.EMAIL_REASON.blockCountry]:
      'The request included an IP address parameter, and the IP is from a country that is blocked in your user settings',
    [this.EMAIL_REASON.noResult]: 'A conclusive result could not be determined',
  };

  VALID_EMAIL_STATUSES = [
    this.EMAIL_STATUS.valid,
    this.EMAIL_STATUS.badRequest,
    this.EMAIL_STATUS.unknown,
  ];

  INVALID_EMAIL_STATUSES = [
    this.EMAIL_STATUS.invalid,
    this.EMAIL_STATUS.acceptAll,
    this.EMAIL_STATUS.risky,
  ];
}

const ApiInstance = new xVerifyApi(apiUrl);

export { ApiInstance as xVerifyApi };
