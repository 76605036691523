import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { MainApi } from '_api';
import { processResponse, processError } from '_helpers/api';

export const getPrices = createAsyncThunk(
  'variants/getPrices',
  async function (params = {}, { rejectWithValue }) {
    try {
      const response = await MainApi.getPrices({
        onlyForSale: false,
        ...params,
      });
      return processResponse(response);
    } catch (e) {
      return rejectWithValue(processError(e));
    }
  }
);

const variantsSlice = createSlice({
  name: 'variants',
  initialState: {
    prices: {
      data: [],
      error: null,
      loading: false,
    },
  },
  reducers: {
    resetErrors(state) {
      state.activities.error = null;
      state.processingOptions.error = null;
      state.states.error = null;
      state.prices.error = null;
      state.fees.error = null;
      state.upsellOffers.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPrices.pending, (state) => {
        state.prices.error = null;
        state.prices.loading = true;
      })
      .addCase(getPrices.fulfilled, (state, action) => {
        state.prices.data = action.payload;
        state.prices.loading = false;
      })
      .addCase(getPrices.rejected, (state, action) => {
        state.prices.error = action.payload;
        state.prices.loading = false;
      });
  },
});

export const { resetErrors } = variantsSlice.actions;

export default variantsSlice.reducer;
