import { config } from '_config';
import { AxiosClient } from '_api/AxiosClient';
import { CATEGORIES } from '_constants/categories';

const { apiUrl } = config;

class MainApi extends AxiosClient {
  constructor(url) {
    super(url);
    this.createClick = this.createClick.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.getProducts = this.getProducts.bind(this);
    this.getPrices = this.getPrices.bind(this);
  }

  createClick({ body }) {
    const path = '/clicks';
    return this.client.post(path, body);
  }

  sendMessage({ body }) {
    const path = '/contact-us';
    return this.client.post(path, body);
  }

  getProducts({ category = CATEGORIES.accounting, ...restParams } = {}) {
    const path = `/category/products/codes`;
    return this.client.get(path, { params: { category, ...restParams } });
  }

  getPrices({ category = CATEGORIES.accounting, ...params } = {}) {
    const path = `/category/${category}/products/prices`;
    return this.client.get(path, { params });
  }
}

const ApiInstance = new MainApi(apiUrl);

export { ApiInstance as MainApi };
