import styled from 'styled-components';

import { ROUTES } from '_constants/routes';
import { theme } from '_constants/theme';

import Navbar from './Navbar';

const items = [
  {
    title: 'Home',
    path: ROUTES.home,
  },
  {
    title: 'About Us',
    path: ROUTES.about,
  },
  {
    title: 'Services',
    subitems: [
      {
        title: 'Tax Preparation',
        path: ROUTES.taxPreparation,
      },
      {
        title: 'Bookkeeping',
        path: ROUTES.bookkeeping,
      },
    ],
  },
  {
    title: 'Contact Us',
    path: ROUTES.contactUs,
  },
];

const StyledOuterContainer = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 20;
  border-bottom: 1px solid #f2f4f7;
  background-color: ${theme.colors.white};
`;

const StyledInnerContainer = styled.div`
  max-width: ${theme.spaces.large30X * 10}px;
  width: 100%;

  padding-top: ${theme.spaces.smallX}px;
  padding-bottom: ${theme.spaces.smallX}px;
  padding-left: ${theme.spaces.large28X - 1}px;
  padding-right: ${theme.spaces.large28X - 1}px;

  @media ${theme.devices.tablet} {
    padding-left: ${theme.spaces.regular}px;
    padding-right: ${theme.spaces.regular}px;
  }
`;

const Header = ({ containerRef }) => {
  return (
    <StyledOuterContainer ref={containerRef}>
      <StyledInnerContainer>
        <Navbar items={items} />
      </StyledInnerContainer>
    </StyledOuterContainer>
  );
};

export default Header;
