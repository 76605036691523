export const processResponse = (response) => {
  if (Array.isArray(response)) {
    return response.map(({ data } = {}) => {
      if (typeof data?.values !== 'function' && data?.values) {
        return data?.values;
      }

      return data;
    });
  }

  if (typeof response?.data?.values !== 'function' && response?.data?.values) {
    return response?.data?.values;
  }

  return response?.data;
};

export const processError = (axiosError) => {
  const status = axiosError?.response?.status || null;
  const error = axiosError?.response?.data || axiosError;

  if (error && error?.errors) {
    return { status, message: error.errors[0] };
  }

  if (error && !error?.errors && error?.message) {
    return { status, message: error.message };
  }

  if (error && !error?.errors && !error?.message && error?.code) {
    return { status, message: `${error.code} Something went wrong` };
  }

  return { status, message: error };
};
