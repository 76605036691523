export const STORAGE_KEYS = {
  productCode: 'productCode',
  processingCode: 'processingCode',
  businessInfo: 'businessInfo',
  packageInfo: 'packageInfo',
  packageId: 'packageId',
  billingInfo: 'billingInfo',
  billingHash: 'billingHash',
  billingCounter: 'billingCounter',
  contactUsInfo: 'contactUsInfo',
  preOrderFlag: 'preOrderFlag',
  orderBeforePayment: 'orderBeforePayment',
  orderAfterPayment: 'orderAfterPayment',
  gclid: 'gclid',
  source: 'source',
  keyword: 'keyword',
  adAccount: 'adAccount',
  requestUri: 'requestUri',
  crmSessionId: 'crmSessionId',
  step: 'step',
};
