const colors = {
  white: '#FFFFFF',
  black: '#000000',
  gray: {
    primary: '#101828',
    secondary: '#475467',
    placeholder: '#818996',
    300: '#D0D5DD',
    700: '#344054',
  },
  green: {
    primary: '#40B03D',
  },
  red: {
    primary: '#BC5353',
  },
  indigo: {
    primary: '#6941C6',
    light: '#E0D4FE',
  },
};

const displaySizes = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptopS: '1024px',
  laptopM: '1280px',
  laptopL: '1440px',
  desktopS: '1680px',
  desktopM: '1920px',
  desktopL: '2560px',
};

const fontSizes = {
  smallest: 8,
  smallX: 12,
  small: 14,
  regular: 16,
  medium: 18,
  large: 20,
  largeX: 22,
  large2X: 24,
  large3X: 26,
  large4X: 28,
  large5X: 30,
  large6X: 32,
  large7X: 34,
  large8X: 36,
  large9X: 40,
  large10X: 44,
  large11X: 48,
  large12X: 52,
  large13X: 56,
  large14X: 60,
  large15X: 64,
  large16X: 68,
  large17X: 72,
  large18X: 76,
};

const spaces = {
  smallest: 2,
  small2X: 4,
  smallX: 8,
  small: 12,
  regular: 16,
  medium: 20,
  large: 24,
  largeX: 28,
  large2X: 32,
  large3X: 36,
  large4X: 40,
  large5X: 44,
  large6X: 48,
  large7X: 52,
  large8X: 56,
  large9X: 60,
  large10X: 64,
  large11X: 68,
  large12X: 72,
  large13X: 76,
  large14X: 80,
  large15X: 84,
  large16X: 88,
  large17X: 92,
  large18X: 96,
  large19X: 100,
  large20X: 104,
  large21X: 108,
  large22X: 112,
  large23X: 116,
  large24X: 120,
  large25X: 124,
  large26X: 128,
  large27X: 132,
  large28X: 136,
  large29X: 140,
  large30X: 144,
};

export const theme = {
  colors,
  devices: {
    mobileS: `(max-width: ${displaySizes.mobileS})`,
    mobileM: `(max-width: ${displaySizes.mobileM})`,
    mobileL: `(max-width: ${displaySizes.mobileL})`,
    tablet: `(max-width: ${displaySizes.tablet})`,
    laptopS: `(max-width: ${displaySizes.laptopS})`,
    laptopM: `(max-width: ${displaySizes.laptopM})`,
    laptopL: `(max-width: ${displaySizes.laptopL})`,
    desktopS: `(max-width: ${displaySizes.desktopS})`,
    desktopM: `(max-width: ${displaySizes.desktopM})`,
    desktopL: `(max-width: ${displaySizes.desktopL})`,
  },
  displaySizes,
  fontSizes,
  spaces,
};
