import { configureStore } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';

import { config } from '_config';

import generalSlice from './general/slice';
import locationSlice from './location/slice';
import contactUsSlice from './contactUs/slice';
import variantsSlice from './variants/slice';

const makeStore = () =>
  configureStore({
    reducer: {
      general: generalSlice,
      location: locationSlice,
      contactUs: contactUsSlice,
      variants: variantsSlice,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }),
    devTools: config.devtools === 'true',
  });

export const wrapper = createWrapper(makeStore);
