import axios from 'axios';

export class AxiosClient {
  constructor(url) {
    this._url = url;
    this._token = null;
  }

  get baseURL() {
    return this._url;
  }

  get token() {
    return this._token;
  }

  set token(value) {
    this._token = value;
  }

  get client() {
    const headers = {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
    };

    if (this._token) {
      headers.Authorization = `Bearer ${this._token}`;
    }

    return axios.create({
      baseURL: this.baseURL,
      headers,
    });
  }
}
