import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { theme } from '_constants/theme';

const StyledButton = styled.button`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;

  padding-top: ${theme.spaces.small - 1}px;
  padding-bottom: ${theme.spaces.small - 1}px;
  padding-left: ${theme.spaces.large}px;
  padding-right: ${theme.spaces.large}px;

  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: ${theme.colors.indigo.primary};
  background-color: ${theme.colors.indigo.primary};

  font-family: 'Inter';
  font-size: ${theme.spaces.regular}px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: ${theme.colors.white};

  transition: all 0.3s ease-out;

  .prefix,
  .suffix {
    display: inline-flex;
    align-items: center;
  }

  .prefix {
    margin-right: ${theme.spaces.small - 2}px;
  }

  .suffix {
    margin-left: ${theme.spaces.small - 2}px;
  }

  ${({ secondary }) =>
    secondary &&
    css`
      border-color: #e0d4fe;
      background-color: ${theme.colors.white};
      color: ${theme.colors.gray.primary};

      &:hover {
        background-color: #f9f5ff;
      }
    `}

  ${({ rounded }) =>
    rounded &&
    css`
      border-radius: 58px;
    `}

  ${({ mobile }) =>
    mobile &&
    css`
      display: none;
    `}

  @media ${theme.devices.tablet} {
    ${({ desktop }) =>
      desktop &&
      css`
        display: none;
      `}

    ${({ mobile }) =>
      mobile &&
      css`
        display: inline-flex;
      `}
  }
`;

// eslint-disable-next-line react/display-name
const Button = forwardRef(
  (
    {
      children,
      type,
      prefix,
      suffix,
      secondary,
      rounded,
      desktop,
      mobile,
      ...restProps
    },
    ref
  ) => {
    return (
      <StyledButton
        ref={ref}
        type={type || 'button'}
        secondary={secondary}
        rounded={rounded}
        desktop={desktop}
        mobile={mobile}
        {...restProps}
      >
        {prefix && <span className="prefix">{prefix}</span>}
        {children}
        {suffix && <span className="suffix">{suffix}</span>}
      </StyledButton>
    );
  }
);

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  type: PropTypes.string,
  prefix: PropTypes.node,
  suffix: PropTypes.node,
  secondary: PropTypes.bool,
  rounded: PropTypes.bool,
  desktop: PropTypes.bool,
  mobile: PropTypes.bool,
};

export default Button;
