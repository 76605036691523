export const ROUTES = {
  home: '/',
  about: '/about',
  taxPreparation: '/tax-preparation',
  bookkeeping: '/bookkeeping',
  contactUs: '/contact-us',
  login: '/login',
  privacy: '/privacy',
  terms: '/terms',
  optInGeneral: '/opt-in-general',
  optInBookkeeping: '/opt-in-bookkeeping',
  optInTaxPreparation: '/opt-in-tax-preparation',
  payment: '/payment',
  upsell: '/upsell',
  thankyou: '/thankyou',
};
